<template>
  <div>
    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Profile</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit }"
              ref="updateForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(updateProfile)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="user.first_name"
                      name="first_name"
                      rules="required"
                      label="First Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="user.last_name"
                      name="last_name"
                      rules="required"
                      label="Last Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="user.email"
                      name="email"
                      rules="required|email"
                      label="Email"
                    />
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingUpdate"
                    type="submit"
                  >
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Change password</h4>
      </div>
    </div>
    <PasswordReset />
    <div v-if="user && user.hasOwnProperty('agency')">
      <div class="row page-title align-items-center">
        <div class="col-sm-4 col-xl-6">
          <h4 class="mb-1 mt-0">Payment Methods</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <SettingsPaymentMethods />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import PasswordReset from "@components/Common/PasswordReset";
import SettingsPaymentMethods from "@components/Agency/PaymentMethods/SettingsPaymentMethods";

export default {
  components:{
    PasswordReset,
    SettingsPaymentMethods
  },
  data() {
    return {
      loadingUpdate: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed:{
    user(){
      return this.$store.getters['auth/user'];
    }
  },

  mounted() {
    // this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
  },

  methods: {
    updateProfile() {
      this.loadingUpdate = true

      this.$store
        .dispatch('auth/updateProfile', this.user)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', this.password)
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },
  },
}
</script>
